import { render, staticRenderFns } from "./about-us.vue?vue&type=template&id=4e12d770&scoped=true"
import script from "./about-us.vue?vue&type=script&lang=js"
export * from "./about-us.vue?vue&type=script&lang=js"
import style0 from "./about-us.vue?vue&type=style&index=0&id=4e12d770&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e12d770",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/wxq/workspace/ssl-odat-patient-wechat/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4e12d770')) {
      api.createRecord('4e12d770', component.options)
    } else {
      api.reload('4e12d770', component.options)
    }
    module.hot.accept("./about-us.vue?vue&type=template&id=4e12d770&scoped=true", function () {
      api.rerender('4e12d770', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/user-center/about-us.vue"
export default component.exports